// Create our number formatter.
const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export default function formatCurrency(number) {
    let formattedNumber = formatter.format(number);
    return formattedNumber;
}

// Transforms numbers into short versions with character notation (ie. "$40M" or "$750K")
export function formatCurrencyAbbreviated(num, fixed) {
    if (num === null||num === undefined) { return null; } // terminate early
	if (num === 0) { return '0'; } // terminate early
	fixed = (!fixed || fixed < 0) ? 1 : fixed; // number of decimal places to show
	var b = (num).toPrecision(2).split("e"), // get power
	k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
	c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(1 + fixed), // divide by power
	d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
	e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
};