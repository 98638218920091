import { Avatar, Box, CardHeader, Container, Divider, Grid, InputAdornment, Link, TextField, Typography } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useState } from 'react';
import coin from '../coin.svg';
import { useHistory } from "react-router-dom";
import formatCurrency from '../formatters/currency';
import { StyledPaper, useStyles } from '../styles';
import FV from '../formatters/futureval';
import Chart from '../components/Chart';

export default function Dashboard() {
    const classes = useStyles();
    
    // Define history to use for routing
    let history = useHistory();

    // Define states to carry user-provided values
    const [starting, setStarting] = useState(0);
    const [investment, setInvestment] = useState(0);
    const [interest, setInterest] = useState(10);
    const [years, setYears] = useState(0);

    // Define portfolio value and inflation-adjusted portfolio value at maturity
    var value = FV(interest/100, parseInt(years, 10), parseInt(-investment, 10), parseInt(-starting, 10), 0);
    var adjustedValue = FV((interest/100)-0.0324, parseInt(years, 10), parseInt(-investment, 10), parseInt(-starting, 10), 0);
    
    // Handle user input
    function handleChange(e, state) {
        state(e.target.value);
    }

    // Establish some states to carry user data
    const [name, setName] = useState("");
    const [photo, setPhoto] = useState("");

    // This listener fires on inital page load and whenever login information changes
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            // User is signed in.
            setName(user.displayName);
            setPhoto(user.photoURL);
        } else {
            // No user is signed in. 
            // We redirect back to the login page.
            history.push('/login');
        }
    });

    // Here we define our function to handle user logout
    function handleLogout() {
        firebase.auth().signOut().then(() => {
            // Sign out successful.
            history.push('/login')
        }).catch((error) => {
            // An error happened.
            window.alert(error)
        })
    }

    return (
        <>
            <Box display="flex" flexDirection="row">
                <img alt="logo" src={coin} style={{height: 40, margin: 15}} />
                <div style={{flex: 1}} />
                <CardHeader 
                    avatar={<Avatar src={photo} />}
                    title={name}
                    subheader={<a href="" style={{textDecoration: 'none', color: "gray"}} onClick={handleLogout}>Logout</a>}
                />
            </Box>
            <Divider variant="middle" />
            <Container>
                <Grid container spacing={2} style={{marginTop: 15}}>
                    <Grid item xs={12} lg={3}>
                        <Typography variant="h6">What if I invest...</Typography>
                        <TextField onChange={(e) => handleChange(e, setStarting)} color="primary" variant="outlined" InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} fullWidth margin="normal"/>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Typography variant="h6">And every year I add...</Typography>
                        <TextField onChange={(e) => handleChange(e, setInvestment)} color="primary" variant="outlined" InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} fullWidth margin="normal"/>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Typography variant="h6">At an annual interest rate of...</Typography>
                        <TextField defaultValue={10} onChange={(e) => handleChange(e, setInterest)} color="primary" variant="outlined" InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}} fullWidth margin="normal"/>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Typography variant="h6">For...</Typography>
                        <TextField defaultValue={0} onChange={(e) => handleChange(e, setYears)} color="primary" variant="outlined" InputProps={{endAdornment: <InputAdornment position="end">Years</InputAdornment>}} fullWidth margin="normal"/>
                    </Grid>
                </Grid>
                <Box hidden={!investment || !years || !interest || !starting}>
                    <Divider light style={{marginTop: 25, marginBottom: 20}} />
                    <Typography variant="h5">Insights</Typography>
                    <Box marginBottom={3}>
                        <Typography variant="caption" color="textSecondary"><b>ⓘ</b> Assumptions are based on a <Link rel="noreferrer" target="_blank" href="https://tradingeconomics.com/united-states/inflation-cpi" color="secondary">3.24% annual inflation rate</Link> and a 4% annual "safe withdrawal" rate.</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <StyledPaper variant="outlined" elevation={0}>
                                <Typography>Your portfolio will be worth <span style={{color: "#41dc65"}}>{formatCurrency(value)}</span> in {years} {years === 1?"year":"years"}, generating <span style={{color: "#41dc65"}}>{formatCurrency(value*.04)}</span> in safe withdrawable income! 🎉</Typography>
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledPaper variant="outlined" className={classes.StyledPaper} elevation={0}>
                                <Typography>After accounting for inflation, that will be like having <span style={{color: "#41dc65"}}>{formatCurrency(adjustedValue)}</span> in today's dollars, generating <span style={{color: "#41dc65"}}>{formatCurrency(adjustedValue*.04)}</span> in safe withdrawable income.</Typography>
                            </StyledPaper>
                        </Grid>
                    </Grid>
                    <Divider light style={{marginTop: 25, marginBottom: 20}} />
                    <Typography variant="h5">Portfolio Value Over Time</Typography>
                    <Box marginBottom={3}>
                        <Typography variant="caption" color="textSecondary"><b>ⓘ</b> Based on a {interest}% annual rate of return on invested capital</Typography>
                    </Box>
                    <Box>
                        <Chart investment={investment} years={years} interest={interest} starting={starting} />
                    </Box>
                    <Divider light style={{marginTop: 25, marginBottom: 20}} />
                    <Typography variant="h5">Key Metrics</Typography>
                    <Box marginBottom={3}>
                        <Typography variant="caption" color="textSecondary"><b>ⓘ</b> Assumptions are based on a <Link rel="noreferrer" target="_blank" href="https://tradingeconomics.com/united-states/inflation-cpi" color="secondary">3.24% annual inflation rate</Link> and a {interest}% annual rate of return on invested capital</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <StyledPaper variant="outlined" elevation={0}>
                                <Typography variant="h4" color="primary">{formatCurrency(value)}</Typography>
                                <Typography>Portfolio Value at Maturity</Typography>
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledPaper variant="outlined" elevation={0}>
                                <Typography variant="h4" color="primary">{formatCurrency(value*.04)}</Typography>
                                <Typography>Annual Safe Withdrawal</Typography>
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledPaper variant="outlined" elevation={0}>
                                <Typography variant="h4" color="primary">{formatCurrency(adjustedValue)}</Typography>
                                <Typography>Portfolio Value at Maturity</Typography>
                                <Typography variant="caption" color="textSecondary">Inflation-adjusted</Typography>
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledPaper variant="outlined" elevation={0}>
                                <Typography variant="h4" color="primary">{formatCurrency(adjustedValue*.04)}</Typography>
                                <Typography>Annual Safe Withdrawal</Typography>
                                <Typography variant="caption" color="textSecondary">Inflation-adjusted</Typography>
                            </StyledPaper>
                        </Grid>
                    </Grid>
                </Box>
                <Box hidden={investment && years && interest && starting} textAlign="center" marginTop={5}>
                    <StyledPaper variant="outlined" elevation={0}>
                        <Typography color="textSecondary">💡 Fill in all fields to see your results</Typography>
                    </StyledPaper>
                </Box>
            </Container>
        </>
    )
}