import coin from '../coin.svg';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Box, Button, Typography } from '@material-ui/core';
import {Link} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useStyles } from '../styles';

export default function Welcome() {
    const classes = useStyles();
    
    // Define history to use for routing
    let history = useHistory();

    // This listener fires on inital page load and whenever login information changes
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            // User is signed in.
            history.push('/dashboard')
        } else {
            // No user is signed in.
        }
    });

    return (
        <Box className={classes.container}>
            <img src={coin} className="App-logo" alt="logo" />
            <Typography variant="h4" style={{marginTop: 10}}>
                <b>Welcome to Whatify</b>
            </Typography>
            <Typography variant="h6" style={{marginTop: 10}}>
                The financial planning app that answers the question:
            </Typography>
            <Typography variant="h6">
                <i>What if I...</i>
            </Typography>
            <Button variant="contained" color="primary" style={{marginTop: 50}} disableElevation component={Link} to="/login">Get Started</Button>
        </Box>
    );
};