import { Box, Typography } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import { StyledPaper, useStyles } from '../styles';
import { useHistory } from "react-router-dom";
import coin from '../coin.svg';
import { StyledFirebaseAuth } from 'react-firebaseui';

export default function Login() {
    const classes = useStyles();
    
    // Define history to use for routing
    let history = useHistory();

    // This listener fires on inital page load and whenever login information changes
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            // User is signed in.
            history.push('/dashboard')
        } else {
            // No user is signed in.
        }
    });

    var uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                history.push('/dashboard');
                return true;
            }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        // Terms of service url.
        tosUrl: '<your-tos-url>',
        // Privacy policy url.
        privacyPolicyUrl: '<your-privacy-policy-url>'
    };

    return (
        <Box className={classes.container}>
            <StyledPaper variant="outlined" elevation={0}>
                <img src={coin} className="App-logo" alt="logo" style={{height: 70, marginTop: 20}} />
                <Typography variant="h5" align="center" style={{marginTop: 10, marginBottom: 40}}>Login / Sign Up</Typography>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </StyledPaper>
        </Box>
    )
}