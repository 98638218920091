import './App.css';
import Welcome from './pages/Welcome';
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/">
					<Welcome />
				</Route>
				<Route path="/dashboard">
					<Dashboard />
				</Route>
				<Route exact path="/login">
					<Login />
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
