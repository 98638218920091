import formatCurrency, { formatCurrencyAbbreviated } from '../formatters/currency';
import FV from '../formatters/futureval';
import { Line } from 'react-chartjs-2';

export default function Chart({ interest, years, investment, starting }) {

    // Here we build our dataset
    function buildData() {
        // Establish a variable to hold data
        let data = [];
        // Establish a counter to use in our for loop
        var i;
        // Take user-provided years and create a data point for portfolio value at each year mark
        for (i = 0; i <= years; i++) {
            data[i] = FV(interest/100, parseInt(i, 10), parseInt(-investment, 10), parseInt(-starting, 10), 0);
        }

        return data;
    };

    // Here we build our array of axis labels
    function buildLabels() {
        let labels = [];
        var i;

        // Create an axis label for each year we have a data point for
        for (i = 0; i <= years; i++) {
            labels[i] = i;
        }

        return labels;
    };
    
    // Defining our datasets, labels, and their layouts
    const data = {
        labels: buildLabels(),
        datasets: [
            {
                fill: false,
                borderColor: '#41dc65',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#41dc65',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#41dc65',
                pointHoverBorderColor: '#41dc65',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: buildData()
            }
        ]
    };

    // Defining styling and formatting options
    const options = {
        animation: false,
        legend: {display: false},
        responsive: true,
        responsiveAnimationDuration: 0,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: function(value) {
                        return `$ ${formatCurrencyAbbreviated(value)}`;
                    }
                }
            }],
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Years'
                }
            }]
        },
        tooltips: {
            intersect: false,
            custom: function(tooltip) {
                if (!tooltip) return;
                // disable displaying the color box;
                tooltip.displayColors = false;
            },
            callbacks: {
                // use callbacks to return the desired label and title formats
                label: function(tooltipItem, data) {
                    return formatCurrency(tooltipItem.yLabel);
                },
                title: function(tooltipItems, data) {
                    return tooltipItems[0].xLabel + " Years"
                }
            }
        }
    }

    return (
        <Line data={data} options={options} />
    )

}