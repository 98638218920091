import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
	palette: {
		type: "dark",
		primary: {
			main: '#41dc65',
		},
		secondary: {
			main: '#22aaea',
		},
		error: {
			main: red.A400,
		},
		background: {
			default: '#282c34',
		},
	},
});

export default theme;